import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
// import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
import VideoDialog from './videoDialog'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import ReviewBar from './ReviewBar'
import GettingStarted from './GettingStarted'
import ContactUs from './ContactUs'
import WorkspaceAndFlow from './WorkspaceAndFlow'
import { listOfUsers } from '../../store/orgUsers/orgUsersSelector'
import { getAfterSignupData } from '../../api'
import { getTableId } from '../../config'

// import MetricsAndAuthentication from './MetricsAndAuthentication'

Chart.register(ArcElement)

function Dashboard() {
  const { orgId } = useParams()
  const { orgObj, allScripts, usersList, isFeedbackSubmitted } = useCustomSelector((state) => ({
    orgObj: state?.orgs?.orgs?.[orgId],
    allScripts: state?.scripts?.scripts,
    usersList: listOfUsers(state),
    isFeedbackSubmitted: state?.appInfo?.isFeedbackSubmitted
  }))

  const [launcher, setLauncher] = useState(false)
  const [open, setOpen] = useState(false)
  const [video, setVideo] = useState('')

  const handleClose = () => setOpen(false)
  const handleOpen = (vid_data) => {
    setVideo(vid_data)
    setOpen(true)
  }
  useEffect(() => {
    return () => {
      if (document.querySelector('script[src="https://control.msg91.com/app/assets/widget/chat-widget.js"]')) {
        window.chatWidget?.close()
        setLauncher(false)
      }
    }
  }, [])

  const [review, setReview] = useState(true)
  const tableId = getTableId('feedback')
  useEffect(() => {
    getAfterSignupData(tableId, { filter: { orgid: orgId }, fields: ['orgid'], addRandomId: true }).then((data) => {
      const feedbackExist = Boolean(data[0]?.orgid)
      setReview(feedbackExist)
    })
  }, [orgId])

  return (
    <Box display='flex' bgcolor='grey.100' width='100%' height='100%' position='relative' overflow='auto' flexDirection='column'>
      <VideoDialog open={open} onClose={handleClose} video={video} />

      {/* Review bar */}
      {!review && !isFeedbackSubmitted && <ReviewBar setReview={setReview} />}

      {/* Getting Started */}
      <GettingStarted handleOpen={handleOpen} />
      {/* Workspace and Flow */}
      <WorkspaceAndFlow orgId={orgId} orgObj={orgObj} allScripts={allScripts} usersList={usersList} />

      {/* Metrics and Authenticated Apps */}
      {/* <MetricsAndAuthentication/> */}

      {/* Contact Us */}
      <ContactUs launcher={launcher} setLauncher={setLauncher} />
    </Box>
  )
}

export default Dashboard
