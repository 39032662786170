import React, { useState } from 'react'
import {
  Avatar,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import config from '../../config'
import { openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { clearScriptsList } from '../../store/scripts/scriptsSlice'
import { MiscTypes, ModalTypeEnums, ParamsEnums } from '../../enums'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { switchOrgId } from '../../utils/utilities'
import { errorToast } from '../customToast'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import CustomModal from '../customModal/customModal'
import AuthorizeOrgModal from '../oauthAuthorization/OauthAuthorizationModal'
import { OrganizationInfoType } from '../../types/Organization.ts'

function AllOrgs({ orgListVariable, orgId, isModal, serviceId }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { appInfo } = useCustomSelector((state: $ReduxCoreType) => ({
    appInfo: state.appInfo
  }))

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [selectedOrg, setSelectedOrg] = useState({})

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedRows = [...orgListVariable].sort((a, b) => {
    if (orderBy) {
      if (order === 'asc') {
        if (orderBy === 'created_at') {
          return new Date(a[orderBy]) - new Date(b[orderBy])
        }
        return a[orderBy]?.localeCompare(b[orderBy])
      }
      if (orderBy === 'created_at') {
        return new Date(b[orderBy]) - new Date(a[orderBy])
      }
      return b[orderBy]?.localeCompare(a[orderBy])
    }
    return 0
  })

  async function selectWorkspace(newOrg: OrganizationInfoType) {
    const newOrgId = newOrg.id
    setSelectedOrg(newOrg)
    if (`${newOrgId}` === orgId) {
      return
    }
    if (appInfo.mode === MiscTypes.OAUTH_MODE) {
      dispatch(openModalRedux({ modalType: ModalTypeEnums.AUTHORIZE_ORG }))
      return
    }
    const OrgObj = orgListVariable.find((item) => item.id === newOrgId)
    try {
      await switchOrgId(OrgObj?.id, OrgObj?.name)
      dispatch({ type: 'RESET_STATE' })
    } catch (error) {
      errorToast("You don't have access to this organization")
      navigate(`${config.orgBaseUrl}`)
    }
    // if (!orgData[newOrgId]?.meta?.org_status) {
    //   window.location.href = `/activate/${newOrgId}`
    // } else {
    // }
    navigate(
      serviceId && !isModal
        ? `${config.projectsBaseUrl}/${newOrgId}/appsexplore/servicepage/${serviceId}`
        : `${config.projectsBaseUrl}/${newOrgId}`
    )
    dispatch(clearScriptsList())
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className='bg-grey-white font-weight-6 ' colSpan={isModal ? null : 1}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Workspace
                </TableSortLabel>
              </TableCell>
              {!isModal && (
                <>
                  <TableCell className='bg-grey-white font-weight-6 w-fitcontent'>
                    <TableSortLabel
                      active={orderBy === 'domainUrl'}
                      direction={orderBy === 'domainUrl' ? order : 'asc'}
                      onClick={() => handleRequestSort('domainUrl')}
                    >
                      Domain
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className='bg-grey-white font-weight-6'>
                    <TableSortLabel
                      active={orderBy === 'created_at'}
                      direction={orderBy === 'created_at' ? order : 'asc'}
                      onClick={() => handleRequestSort('created_at')}
                      sx={{ textWrap: 'nowrap' }}
                    >
                      Created At
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className='bg-grey-white font-weight-6'>
                    <TableSortLabel
                      active={orderBy === 'email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={() => handleRequestSort('email')}
                    >
                      Created By
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((org) => (
              <TableRow key={org.id} hover className='org-row cursor-pointer' onClick={() => selectWorkspace(org)}>
                <TableCell>
                  <Box display='flex' alignItems='center' className='gap-2'>
                    <Avatar
                      variant='rounded'
                      className='bg-gray-white-medium'
                      sx={{
                        width: 26,
                        height: 26,
                        color: 'grey'
                      }}
                    >
                      {org.meta?.iconUrl ? (
                        <IconWrapper size={isModal ? '24px' : '24px'} iconUrl={org.meta?.iconUrl} />
                      ) : (
                        <Typography variant={isModal ? 'h7' : 'bold'}>{org.name?.toUpperCase()?.substring(0, 1) || ''}</Typography>
                      )}
                    </Avatar>
                    <Typography variant='body2'>{org.name}</Typography>
                  </Box>
                </TableCell>
                {!isModal && (
                  <>
                    <TableCell>{org.meta?.domainUrl}</TableCell>
                    <TableCell>{org.created_at?.substr(0, org.created_at.indexOf('T'))}</TableCell>
                    <TableCell>{org.email.length > 25 ? `${org.email.substring(0, 25)}...` : org.email}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModal
        className='orgbox__model'
        fullScreen={false}
        openModal={appInfo.mode === 'oauth' && appInfo.isModalOpen && appInfo.modalType === ModalTypeEnums.AUTHORIZE_ORG}
      >
        <AuthorizeOrgModal selectedOrg={selectedOrg} />
      </CustomModal>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(AllOrgs), [ParamsEnums.orgId, ParamsEnums.serviceId]))
