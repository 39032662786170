import { createAsyncThunk } from '@reduxjs/toolkit'
import { createdProject, getProjects, updateProject, updateProjectStatus, getOneProject, moveProject } from '../../api/index'
import { MiscTypes } from '../../enums'
import { setScriptsReducer } from '../scripts/scriptsSlice'

export const saveProjects = createAsyncThunk('projects/saveProjects', async (payload) => {
  const data = await createdProject(payload, payload.type)
  return { payload: data.data.data, type: payload.type }
})

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async (payload, { dispatch }) => {
  const data = await getProjects(payload.orgId, payload.type)
  const projectList = data?.data?.data?.projects
  const flowsOfThisOrg = data?.data?.data?.flows
  dispatch(setScriptsReducer(flowsOfThisOrg))
  return { projectsData: projectList, orgId: payload.orgId, type: payload.type }
})

export const removeProjects = createAsyncThunk('projects/removeProjects', async () => {
  return 5
})

export const changeProjectStatus = createAsyncThunk('projects/changeProjectStatus', async (payload) => {
  await updateProjectStatus(payload?.projectInfo.org_id, payload?.projectInfo.id, payload?.status)
  return payload
})

export const updateProjects = createAsyncThunk('projects/updateProjects', async (payload) => {
  const type = payload.type === MiscTypes.EMBED_MODE ? MiscTypes.EMBED_MODE : MiscTypes.FLOW
  const data = await updateProject(payload.id, payload.dataTosend)
  return { payload: data.data.data, type }
})

export const addOneProjectThunk = createAsyncThunk('projects/addOneProjectThunk', async (payload) => {
  const data = await getOneProject(payload.dataToSend.projectId)
  return { singleProjectData: data.data.data, type: payload.dataToSend.type }
})

/**
 * Moves a project from one organization to another.
 * @param {Object} payload - The payload containing the project ID, old organization ID, and new organization ID.
 * @returns {Object} - The payload object.
 */
export const moveProjectThunk = createAsyncThunk('projects/moveProjectThunk', async (payload) => {
  // Call the moveProject function with the provided parameters
  await moveProject(payload.id, payload.old_org_id, payload.new_org_id)
  return payload
})
