import { useNavigate } from 'react-router-dom'
import React from 'react'
import { Box, Typography } from '@mui/material'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import ShowTriggerIcon from '../../components/functiondashboard/workFlow/workFlowNavbar/workFlowTitleDetail/ShowTriggerIcon.tsx'
import { timeAgo } from '../../utils/utilities'
import config from '../../config'

function RecentWorkflows({ orgId }) {
  const navigate = useNavigate()
  const { recentFlows } = useCustomSelector((state) => {
    const allScriptsJson = state?.scripts?.scripts
    const orderOfScriptsLastUpdated = state.scripts?.orderOfScriptsLastUpdated || []
    const recentFlows = []
    orderOfScriptsLastUpdated?.slice(0, 5)?.forEach((scriptId) => {
      recentFlows.push(allScriptsJson[scriptId])
    })
    return {
      recentFlows
    }
  })
  const recentFlowsLength = recentFlows?.length

  function redirectToFlow(flow) {
    const flowId = flow?.identifier
    const projectId = flow?.project_id
    navigate(`${config.projectsBaseUrl}/${orgId}/${projectId}${config.workflowBaseUrl}/${flowId}/${Tabnames.DRAFT}`)
  }
  return (
    <Box
      className='h-100 pos-rel  p-5  column overflow-scroll-y '
      width='70%'
      border='1px solid black'
      borderLeft='none'
      borderRight='none'
    >
      <Typography variant='h5'>Recent Flows</Typography>
      {recentFlows?.map((flow, index) => {
        return (
          <Box
            className='p-3 cur-pointer hoverable-block w-100 gap-2 flex-spaceBetween-center '
            onClick={() => redirectToFlow(flow)}
            key={flow.identifier}
            sx={{
              borderBottom: recentFlowsLength - 1 === index ? 'unset' : '0.2px solid #ccc'
            }}
          >
            <Box className='flex-start-center gap-3'>
              <ShowTriggerIcon triggerInfo={flow?.json_script?.trigger} />
              <Box>
                <Typography variant='h7'>{flow.title}</Typography>
                <Typography>{timeAgo(flow?.updatedAt)}</Typography>
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(RecentWorkflows), [ParamsEnums.orgId]))
