import { createSlice } from '@reduxjs/toolkit'

// // reducer imports
// eslint-disable-next-line import/no-cycle
import { initialState, reducers, extraReducers } from './scriptsReducer'

const scriptsSlice = createSlice({
  name: 'scripts',
  initialState,
  reducers,
  extraReducers
})

export const {
  addScriptInRedux,
  addScriptAndUpdateList,
  clearScriptsList,
  setDryRunResponseForScript,
  addInAppIntegration,
  setScriptsReducer
} = scriptsSlice.actions

export default scriptsSlice.reducer
