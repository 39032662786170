/* eslint-disable import/no-unresolved */
import { ApiSharp, JavascriptOutlined } from '@mui/icons-material'
import CodeIcon from '@mui/icons-material/Code'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Typography } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { customAlphabet } from 'nanoid'
import { useEffect, useRef } from 'react'
import { getBrandDetails, switchOrg } from '../api/index'
import { errorToast } from '../components/customToast'
import config from '../config'
import { ALERTTYPES, BlockTypes, MiscTypes, ParamsEnums } from '../enums'
import axios from '../interceptor/interceptor'
import {
  ApiInitialInstance,
  FunctionInitialInstance,
  IfInitialInstance,
  VariableInitialInstance,
  initialStatePlugin
} from '../store/enum.ts'
import { sendAlert } from './alertUtility.ts'
import { evalVariableAndCodeFromContext } from './codeUtility.ts'
import isEqual from './deepCheckSelector'
import { INPUT_FIELDS_SUGGESTIONS } from '../pages/developerHub/constants/developerHubConstants.ts'
import { flattenToJSON } from '../store/plugin/currentSelectedPlugin/utility.ts'
import { store } from '../store'

/** Holds Talk To Support Dialog Card Data Icons */
import blogging from '../assests/support-dialog-img/blogging.svg'
import community from '../assests/support-dialog-img/community.svg'
// import expert from '../assests/support-dialog-img/expert.svg'
import email from '../assests/support-dialog-img/email.svg'
import knowledge from '../assests/support-dialog-img/knowledge.svg'
import request from '../assests/support-dialog-img/request.svg'

export const areAllKeysOthers = (groupedActions) => {
  return Object.keys(groupedActions).every((key) => key === 'others')
}

export const switchOrgId = async (orgId, orgName) => {
  try {
    const response = await switchOrg({ id: orgId, name: orgName })
    if (process.env.REACT_APP_API_ENVIRONMENT === 'local') {
      const domain = getCurrentEnvironment()
      setInCookies(domain, response?.data?.data?.localToken)
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      throw new Error('403 Forbidden')
    } else {
      console.error('Error:', error)
    }
  }
}

export const isJSONString = (str, passEmpty) => {
  try {
    JSON.parse(str)
    return JSON.parse(str)
  } catch (error) {
    if (passEmpty) return str
    return {}
  }
}

export const groupingCategory = (data) =>
  data?.reduce((acc, action) => {
    const category = action.category || 'others'

    if (category) {
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(action)
    }
    return acc
  }, {})

export const valueToKeyConvert = (json, type) => {
  const newArray = []
  if (type === 'KVD') {
    Object.entries(json).forEach((entry) => {
      const newData = {
        name: entry[0],
        value: entry[1],
        comment: ''
      }
      newArray.push(newData)
    })
  }
  if (type === 'NV')
    Object.entries(json).forEach((entry) => {
      const newData = {
        name: entry[0],
        value: entry[1]
      }
      newArray.push(newData)
    })
  return newArray
}
export const validateURL = (string) => {
  let givenURL
  try {
    givenURL = new URL(string)
    return givenURL
  } catch (error) {
    console.error('error is', error)
    return false
  }
}
export const truncateString = (name, maxLength) => {
  return name?.length > maxLength ? `${name.substring(0, maxLength)}...` : name
}
export const getContentType = (obj) => {
  let i = 0
  const arr = Object?.entries(obj)
  while (i < arr.length) {
    if (arr[i][0]?.toLowerCase() === 'content-type') {
      return arr[i][1]
    }
    i++
  }
  return ''
}
export const getRawType = (value) => {
  if (value === 'JavaScript (application/Javascript)') {
    return 'application/Javascript'
  }
  if (value === 'Text (text/plain)') {
    return 'text/plain'
  }
  if (value === 'XML (application/xml)') {
    return 'application/xml'
  }
  if (value === 'XML (text/xml)') {
    return 'text/xml'
  }
  return 'text/html'
}

export const makeUrl = (url, params) => {
  url = url || ''
  if (!params) {
    return url
  }
  let indexOfQsn = url?.length
  if (url?.includes('?')) {
    for (let i = 0; i < url.length; i++) {
      if (url[i] === '?') {
        const substring = url.substring(0, i)
        if (substring.lastIndexOf('}') >= substring.lastIndexOf('${context')) {
          indexOfQsn = i
          break
        }
      }
    }
  }
  let newUrl = url?.substring(0, indexOfQsn)
  const obj = params
  newUrl += '?'
  for (let i = 0; i < obj?.length; i++) {
    newUrl += obj[i].name
    if (obj[i].value) newUrl += `=${obj[i].value}&`
  }
  if (newUrl?.endsWith('?')) {
    return newUrl?.slice(0, -1)
  }
  return newUrl
}
export const makeUrlForSuggest = (queryHtml, urlHtml) => {
  let newUrlHtml = urlHtml?.split('?')[0]
  newUrlHtml += '?'
  const replacedQueryHtml = replaceCharactersForQueryParams(queryHtml)
  newUrlHtml += replacedQueryHtml
  if (newUrlHtml?.endsWith('?')) {
    return newUrlHtml?.slice(0, -1)
  }
  return newUrlHtml
}
function replaceCharactersForQueryParams(htmlString) {
  const regex = /([\s])/g
  const replacedString = htmlString?.replace(regex, (match, group1) => {
    if (group1 === ':') {
      return '='
    }
    if (group1 === ' ' || group1 === '\n') {
      return '&'
    }
    return match
  })
  return replacedString
}
export const convertArrToString = (variables) => {
  let i = 0
  let str = ''
  for (; i < variables?.length; i++) {
    const values = Object.values(variables[i])
    let j = 0
    let valid = false
    let str1 = ''
    for (; j < values?.length; j++) {
      if (values[j]?.length !== 0) {
        valid = true
      }
      str1 += values[j]
      if (j !== values.length - 1) {
        str1 += ':'
      }
    }
    if (valid) {
      str += str1
      str += '\n'
    }
  }
  return str
}
export const getStringToArrayObject = (type, data, ishtml = false) => {
  let fields = []
  let send = true
  switch (type) {
    case 1:
      fields = ['name', 'value']
      break
    case 2:
      fields = ['name', 'value']
      break
    case 3:
      fields = ['key', 'value', 'comment']
      break
    default:
      send = false
      break
  }
  if (send) {
    return bulkAddFunction(fields, data, ishtml)
  }
  return []
}
export const getChildrenOfAddress = (key, inputJsonToUpdate, index = 0) => {
  if (key.length === index) return inputJsonToUpdate || []
  for (let i = 0; i < inputJsonToUpdate.length; i++) {
    if (inputJsonToUpdate[i].key === key[index]) {
      if (inputJsonToUpdate[i].type === 'input groups') {
        return getChildrenOfAddress(key, inputJsonToUpdate[i].children, index + 1)
      }
      return inputJsonToUpdate[i].children || []
    }
  }
  return []
}
export const bulkAddFunction = (fields, data) => {
  if (fields.length === 0) {
    return []
  }
  let keyValue = data
  if (typeof data === 'string') keyValue = data?.split('\n')
  let variables = []
  let i = 0
  for (; i < keyValue?.length; i++) {
    if (keyValue[i].length !== 0) {
      const objArr = splitUrlWithChar(keyValue[i], ':')
      let j = 0
      let obj = {}
      for (; j < fields.length; j++) {
        let valueH = ''
        if (j < objArr.length) {
          valueH = objArr[j]?.trim()
        }
        obj = { ...obj, [fields[j]]: valueH }
      }
      variables = [...variables, obj]
    }
  }
  return variables
}
export const getInfoParamtersFromUrl = () => {
  const params = new URLSearchParams(window.location.search)
  const urlParameters = {}
  params.forEach((value, key) => {
    urlParameters[key] = value
  })
  if (urlParameters.versionId) urlParameters.versionIdOrStepId = params.get('versionId') || ''
  else if (urlParameters.stepId) urlParameters[ParamsEnums.versionIdOrStepId] = params.get('stepId')
  const urlPath = window.location.pathname.slice(1)?.split('/')

  if (urlPath.length >= 2 && urlPath[1] === 'embed') {
    urlParameters.orgId = urlPath[2]
    urlParameters.projectId = urlPath[3]
    if (urlPath[4] === 'workflow') {
      urlParameters.scriptId = urlPath[5]
      urlParameters.tabName = urlPath[6]
      // '/integrations/embed/:orgId/:projectId/event/:eventId/service/:serviceId/workflow/:scriptId/:tabName'
      urlParameters[ParamsEnums.sectionIdOrScriptId] = urlPath[5]
    } else if (urlPath[4] === 'event') {
      // '/integrations/embed/:orgId/:projectId/event/:eventId'
      urlParameters.eventId = urlPath[5]
      // '/integrations/embed/:orgId/:projectId/event/:eventId/service/:serviceId'
      if (urlPath[6] === 'service') urlParameters.serviceId = urlPath[7]
      // '/integrations/embed/:orgId/:projectId/event/:eventId/service/:serviceId/:combinationId'
      if (urlPath[8] && urlPath[8] !== 'workflow') urlParameters.combinationId = urlPath[8]
      else if (urlPath[8]) {
        urlParameters.scriptId = urlPath[9]
        urlParameters.tabName = urlPath[10]
        // '/integrations/embed/:orgId/:projectId/event/:eventId/service/:serviceId/workflow/:scriptId/:tabName'
        urlParameters[ParamsEnums.sectionIdOrScriptId] = urlPath[9]
      }
    } else if (urlPath[4] === 'service') {
      // '/integrations/embed/:orgId/:projectId/service/:serviceId'
      urlParameters.serviceId = urlPath[5]
      // '/integrations/embed/:orgId/:projectId/service/:serviceId/:combinationId'
      if (urlPath[6] && urlPath[6] !== 'workflow') urlParameters.combinationId = urlPath[6]
      else if (urlPath[6]) {
        urlParameters.scriptId = urlPath[7]
        urlParameters.tabName = urlPath[8]
        urlParameters[ParamsEnums.sectionIdOrScriptId] = urlPath[7]
      }
    }
  } else if (urlPath.length === 2) {
    if (urlPath[0] === 'activate') {
      urlParameters.orgId = urlPath[1]
    }
    if (
      [
        config.projectsBaseUrl,
        config.packageBaseUrl,
        config.developerBaseUrl,
        config.authBaseUrl,
        config.integrations,
        config.orgBaseUrl
      ].includes(`/${urlPath[0]}`)
    )
      urlParameters.orgId = urlPath[1]
    if (`/${urlPath[0]}` === '/i') urlParameters.interfaceId = urlPath[1]
  } else if (urlPath.length === 3) {
    if (urlPath[0] === 'activate' && urlPath[2] === 'billing') {
      urlParameters.orgId = urlPath[1]
    }
    if (`/${urlPath[0]}` === config.interface) {
      // /${config.projectsBaseUrl}/:orgId/:projectId
      urlParameters.projectId = urlPath[1]
      urlParameters.interfaceId = urlPath[1]
    }
    if (`/${urlPath[0]}` === config.projectsBaseUrl) {
      // /${config.projectsBaseUrl}/:orgId/:projectId
      urlParameters.projectId = urlPath[2]
      urlParameters.orgId = urlPath[1]
    }
    if (`${urlPath[1]}/${urlPath[2]}` === config.addNewAuth) {
      urlParameters.orgId = urlPath[0]
    }
    if (`${urlPath[1]}/${urlPath[2]}` === config.authCongoPage) {
      urlParameters.orgId = urlPath[0]
    }
    if (`/${urlPath[0]}` === config.orgBaseUrl && ['setting', 'invite'].includes(urlPath[2])) {
      // /${config.orgBaseUrl}/:orgId/invite
      urlParameters.orgId = urlPath[1]
    }
  } else if (urlPath.length === 4) {
    if (`/${urlPath[0]}` === config.projectsBaseUrl) {
      urlParameters.orgId = urlPath[1]
      urlParameters.projectId = urlPath[2]
      urlParameters.interfaceId = urlPath[3]
    }
    if (`/${urlPath[0]}` === config.developerBaseUrl && urlPath[2] === 'plugin') {
      // `${developerBaseUrl}/:orgId/plugin/:pluginId`
      urlParameters.orgId = urlPath[1]
      urlParameters.pluginId = urlPath[3]

      // urlParameters[ParamsEnums.pluginIdOrScriptId] = urlPath[3]
    } else if ((`/${urlPath[0]}` === config.projectsBaseUrl && urlPath[3] === 'setup') || urlPath[0] === 'integrations') {
      // /integrations/orgid/projectid/configuration -for second '||' condition
      urlParameters.orgId = urlPath[1]
      urlParameters.projectId = urlPath[2]
    }
  } else if (urlPath.length === 5) {
    if (`/${urlPath[0]}` === 'makeflow') {
      urlParameters.triggerId = urlPath[2]
      urlParameters.actionId = urlPath[4]
    } else if (`/${urlPath[0]}` === config.developerBaseUrl && urlPath[2] === 'plugin') {
      // `${config.developerBaseUrl}/:orgId/plugin/:pluginId/:sectionKey`
      urlParameters.orgId = urlPath[1]
      urlParameters.pluginId = urlPath[3]
      urlParameters.sectionKey = urlPath[4]
    } else if (`/${urlPath[0]}` === config.embedBaseUrl && urlPath[3] === 'embedProjects') {
      // `${embedBaseUrl}/org/:orgId/embedProjects/:projectId`
      urlParameters.orgId = urlPath[2]
      urlParameters.projectId = urlPath[4]
    } else if (`/${urlPath[5]}` === 'interfaceSetup') {
      //
      urlParameters.orgId = urlPath[1]
      urlParameters.projectId = urlPath[2]
      urlParameters.interfaceId = urlPath[3]
    } else {
      urlParameters.orgId = urlPath[1]
      urlParameters.projectId = urlPath[2]
      urlParameters.interfaceId = urlPath[3]
      urlParameters.serviceId = urlPath[4]
    }
  } else if (urlPath.length === 6) {
    if (`/${urlPath[0]}` === config.developerBaseUrl && urlPath[2] === 'plugin') {
      // ${developerBaseUrl}/:orgId/plugin/:pluginId/:sectionKey/:sectionId
      urlParameters.orgId = urlPath[1]
      urlParameters.pluginId = urlPath[3]
      urlParameters.sectionKey = urlPath[4]
      urlParameters.sectionId = urlPath[5]
      urlParameters[ParamsEnums.sectionIdOrScriptId] = urlPath[5]
      if (urlPath[4] === 'auth') {
        urlParameters[ParamsEnums.versionIdOrStepId] = urlPath[3]
      }
      urlParameters.tabName = 'draft'
      // urlParameters[ParamsEnums.pluginIdOrScriptId] = urlPath[3]
      // urlParameters[ParamsEnums.sectionIdOrStepId] = urlPath[5]
    } else if ((`/${urlPath[0]}` === config.projectsBaseUrl || `/${urlPath[0]}` === '/integrations') && urlPath[3] === 'workflows') {
      // /${config.projectsBaseUrl}/:orgId/:projectId/workflows/:scriptId/:tabName
      urlParameters.orgId = urlPath[1]
      urlParameters.projectId = urlPath[2]
      urlParameters.scriptId = urlPath[4]
      urlParameters.tabName = urlPath[5] === 'logs' ? 'draft' : urlPath[5]

      // urlParameters[ParamsEnums.pluginIdOrScriptId] = urlPath[4]
      // urlParameters[ParamsEnums.sectionIdOrStepId] = params.get('stepId')
      urlParameters[ParamsEnums.sectionIdOrScriptId] = urlPath[4]
    }
  } else if (urlPath.length === 7) {
    if (urlPath[6] === 'slider') {
      urlParameters.orgId = urlPath[1]
      urlParameters.projectId = urlPath[2]
      urlParameters.scriptId = urlPath[4]
      urlParameters.tabName = urlPath[5] === 'logs' ? 'draft' : urlPath[5]
      urlParameters[ParamsEnums.sectionIdOrScriptId] = urlPath[4]
    } else {
      urlParameters.orgId = urlPath[1]
      urlParameters.projectId = urlPath[2]
      urlParameters.serviceId = urlPath[4]
      urlParameters.eventId = urlPath[6]
    }
  } else if (urlPath.length === 8) {
    urlParameters.orgId = urlPath[1]
    urlParameters.projectId = urlPath[2]
    urlParameters.serviceId = urlPath[4]
    urlParameters.scriptId = urlPath[6]

    urlParameters.tabName = urlPath[7] === 'logs' ? 'draft' : urlPath[7]

    // urlParameters[ParamsEnums.pluginIdOrScriptId] = urlPath[6]
    // urlParameters[ParamsEnums.sectionIdOrStepId] = params.get('stepId')
    urlParameters[ParamsEnums.sectionIdOrScriptId] = urlPath[6]
  }
  if (!urlParameters.tabName) urlParameters.tabName = 'draft'
  if (window.location.pathname.includes('appsexplore')) {
    urlParameters.orgId = urlPath[1]
    if (urlPath[2] !== 'appsexplore') urlParameters.projectId = urlPath[2]
    else delete urlParameters.projectId

    const nextIndexAfterServicePage = urlPath.indexOf('servicepage') + 1
    if (urlPath[nextIndexAfterServicePage]) {
      urlParameters.firstServiceId = urlPath[nextIndexAfterServicePage]
    }

    const nextIndexAfterService = urlPath.indexOf('service') + 1
    if (urlPath[nextIndexAfterService] && urlPath.indexOf('service') >= 0) {
      urlParameters.serviceId = urlPath[nextIndexAfterService]
    } else {
      delete urlParameters.serviceId
    }
  }
  if (!urlParameters.orgId && urlParameters.orgid) urlParameters['orgId'] = urlParameters.orgid

  // to get threadid according to url
  let threadIdToSet = ''
  const stepId = urlParameters?.versionIdOrStepId
  const scriptId = urlParameters?.sectionIdOrScriptId
  const orgId = urlParameters['orgId'] || ''
  if (stepId) threadIdToSet = `${scriptId}${stepId}`
  else if (scriptId) threadIdToSet = scriptId
  else threadIdToSet = orgId
  urlParameters[ParamsEnums.threadId] = threadIdToSet

  return urlParameters
}

export const convertArrayToObjectOrNot = (value, condition) => {
  if (condition) {
    return value[0]?.name ? value : [{ name: '', value: '' }]
  }
  const data = {}
  for (let i = 0; i < value?.length; i++) {
    const tempKey = value[i]?.name
    if (tempKey?.length !== 0) {
      data[tempKey] = value[i]?.value
    }
  }
  return data
}
export const urlWithParams = (value) => {
  value = value || ''
  let str = ''
  let indexOfQsn = value?.length
  if (value?.includes('?')) {
    for (let i = 0; i < value?.length; i++) {
      if (value[i] === '?') {
        const substring = value?.substring(0, i)
        if (substring.lastIndexOf('}') >= substring.lastIndexOf('${context')) {
          indexOfQsn = i
          break
        }
      }
    }
    const queryparams = value.substring(indexOfQsn + 1)
    const params = queryparams?.split('&')
    let pair = null
    params.forEach(function (d) {
      if (d.length !== 0) {
        pair = splitUrlWithChar(d, '=')
        str = `${str + (pair[0] || '')}:${pair[1] || ''}\n`
      }
    })
  }
  return { value, str, url: value?.substring(0, indexOfQsn) }
}
export function extractQueryParamsAndReturnHtml(html) {
  if (html?.length < 0 || !html) return ''
  const pattern = /\?(.*)/
  const match = html.match(pattern)
  if (!match) return ''
  const queryString = match[1]
  const replacestring = replaceCharactersForUrlQueryParams(queryString)
  const parser = new DOMParser()
  const doc = parser.parseFromString(replacestring, 'text/html')
  return doc.documentElement.innerHTML
}
function replaceCharactersForUrlQueryParams(htmlString) {
  // let firstEqualsReplaced = false
  const replacedString = htmlString.replace(/<span[^>]*>.*?<\/span>|(&amp;|=)/g, function (match, group1) {
    if (group1 === '=') {
      // firstEqualsReplaced = true
      return ':'
    }
    if (group1 === '&amp;') return '\n'
    return match
  })
  return replacedString
}
export const splitUrlWithChar = (url, char) => {
  let front = ''
  let back = ''
  let ch = false
  for (let i = 0; i < url.length; i++) {
    if (!ch && url[i] === char) ch = true
    else if (!ch) {
      front += url[i]
    } else {
      back += url[i]
    }
  }
  return [front, back]
}
export const extractCompanyNameFromEmail = (email) => {
  const domainRegex = /@([\w-]+(?:\.[\w-]+)+)$/
  const match = email.match(domainRegex)
  if (match && match.length > 1) {
    const domain = match[1]
    const lastIndex = domain.lastIndexOf('.')
    if (lastIndex !== -1) {
      return domain.substring(0, lastIndex)
    }
    return domain
  }
  return null
}
export function replaceSpacesWithUnderscores(str) {
  return str?.trim()?.split(' ').join('_')
}
export function replaceUnderscoreWithSpace(str) {
  return str?.trim()?.split('_').join(' ')
}
export const generateNewId = (length = 8) => {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', length)
  return nanoid()
}

export const getVariablesFromPath = (paths, text) => {
  const regex = /\bcontext\S*/g
  const matches = text.match(regex)
  const finalVariables = {}
  for (let i = 0; i < matches?.length; i++) {
    finalVariables[matches[i]] = getValueFromPath(paths, matches[i])
  }
  return finalVariables
}
const getValueFromPath = (paths, text) => {
  paths = createPathObject(paths)
  text = text.replace(/\?/g, '')
  const valsPath = paths.find((path) => path.content === text)
  return valsPath?.value
}
export function createPathObject(groupedSuggestions) {
  const mergedArray = [].concat(...Object.values(groupedSuggestions))
  return mergedArray
}
export function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailPattern.test(email)
}
export function validateDomain(domain) {
  const domainPattern = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+)\.(?:[a-zA-Z]{2,})(?:\/\S*)?$/
  return domainPattern.test(domain)
}
export function extractDomain(url) {
  const parts = url?.split('/')
  const [, , domain] = url.indexOf('://') > -1 ? parts : [parts[0], '', parts[0]]
  const cleanedDomain = domain?.split(':')[0]?.split('?')[0]
  return cleanedDomain.startsWith('www.') ? cleanedDomain.slice(4) : cleanedDomain
}
export const debounce = (func, delay) => {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
export const throttle = (func, limit) => {
  let inThrottle
  return function (...args) {
    if (!inThrottle) {
      func.apply(this, args)
      inThrottle = true
      setTimeout(() => {
        inThrottle = false
      }, limit)
    }
  }
}
export function UsetraceParentPropsUpdate(props) {
  const prevProp = useRef(props)
  useEffect(() => {
    Object.entries(props).reduce((previousStateKaSingleObject, [key, value]) => {
      if (prevProp.current[key] !== value) {
        previousStateKaSingleObject[key] = [prevProp.current[key], value]
      }
      return previousStateKaSingleObject
    }, {})
    prevProp.current = props
  })
}
export function searchObjectForValue(obj, searchString, path = '') {
  let paths = []
  // Iterate over all object properties
  Object.entries(obj).forEach(([key, value]) => {
    // Construct the current path
    const currentPath = path ? `${path}.${key}` : key

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // If the value is an object (and not null or an array), recurse
      paths = paths.concat(searchObjectForValue(value, searchString, currentPath))
    } else if (value?.toString().includes(searchString)) {
      // If the value is not an object, convert it to string and check if it includes the search string
      paths.push(currentPath)
    }
  })

  return paths
}
export function formatStringAroundSubstring(inputString, substring, charsBeforeAfter = 5) {
  const index = inputString.indexOf(substring)

  if (index === -1) {
    // Substring not found
    return inputString
  }

  // Calculate start and end indices for slicing
  let start = index - charsBeforeAfter
  let end = index + substring.length + charsBeforeAfter

  // Adjust start and end if they exceed the string's boundaries
  start = start < 0 ? 0 : start
  end = end > inputString.length ? inputString.length : end

  // Construct and return the formatted string
  return `${start > 0 ? '...' : ''}${inputString.substring(start, end)}${end < inputString.length ? '...' : ''}`
}
export function capitalizeWords(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
export const toUpperCaseWord = (str) => {
  return str.toUpperCase()
}
export const makeWholeWordUpperCase = (str) => {
  return str.toUpperCase()
}
export async function GetUrlFromDomain(domain) {
  let filteredDomain = extractDomainFromString(domain)
  if (!filteredDomain) {
    return ''
  }
  let url
  try {
    url = (await getBrandDetails(filteredDomain))?.data?.favicon
    return url
  } catch (error) {
    if (!filteredDomain) return '-1'
    if (!filteredDomain.includes('http')) {
      filteredDomain = `http://${filteredDomain}`
    }
    if (
      await validateIcon(
        `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${filteredDomain}&size=64`
      )
    ) {
      url = `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${filteredDomain}&size=64`
    } else if (await validateIcon(`https://api.faviconkit.com/${filteredDomain}/144`)) {
      url = `https://api.faviconkit.com/${filteredDomain}/144`
    } else if (await validateIcon(`https://favicons-api.com/api/v1/icons/${filteredDomain}`)) {
      url = `https://favicons-api.com/api/v1/icons/${filteredDomain}`
    } else if (await validateIcon(`http://favicongrabber.com/api/grab/${filteredDomain}`)) {
      url = `http://favicongrabber.com/api/grab/${filteredDomain}`
    } else if (await validateIcon(`http://getfavicon.appspot.com/${encodeURIComponent(filteredDomain)}`)) {
      url = `http://favicongrabber.com/api/grab/${filteredDomain}`
    } else if (await validateIcon(`https://www.${filteredDomain}/images/favicon.ico`)) {
      url = `https://www.${filteredDomain}/images/favicon.ico`
    }
  }

  // else {
  //   let subDomain = domain?.split('://')
  //   subDomain = subDomain[1]?.split(".")
  //   if (subDomain.length > 2)
  //     subDomain.splice(0, 1);
  //   console.log(subDomain, domain);
  //   subDomain = subDomain.join(".")
  //   if (!subDomain.includes('http')) {
  //     subDomain = "http://" + subDomain
  //   }
  //   if (subDomain !== domain)
  //     GetUrlFromDomain(subDomain)
  // }
  if (!(await validateIcon(url))) url = '-1'
  return url
}
async function validateIcon(url) {
  // try {
  //   const response = await fetch(url, { mode: 'no-cors' });
  //   // Check if the response status is 200 (OK)
  //   if (response.ok) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } catch (error) {
  //   return false;
  // }
  return new Promise((resolve) => {
    const img = new Image()
    img.src = url
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
  })
}
export function validateDuplicateScriptName(allScripts, funId, newTitle) {
  let valid = true
  let msg = ''
  newTitle = newTitle.trim()
  if (newTitle?.length < 1) {
    valid = false
    msg = 'Script name cannot be empty'
  }

  allScripts.forEach((script) => {
    if (script.title === newTitle && script.id !== funId) {
      valid = false
      msg = 'Script with same name already exists'
    }
  })
  return [valid, msg]
}

export function isValidURL(url) {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocals
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!urlPattern.test(url)
}
export const returnTextAndUrl = (url) => {
  const urlArray = url?.split(' ')
  let finalString = '('
  urlArray.forEach((value) => {
    if (isValidURL(value)) {
      finalString += `<a href=${value} target="_blank">${value}</a>`
    } else {
      finalString += `${value} `
    }
  })
  finalString += ')'
  return finalString
}
function groupChildKeys(obj, path) {
  const result = []
  if (['string', 'number', 'boolean'].includes(typeof obj)) {
    result.push({
      display: makeDislay(path),
      id: `${path}`,
      value: obj
    })
    return result
  }
  if (!obj) return []

  Object.keys(obj)?.forEach((key) => {
    const value = obj[key]

    let content = lastIndexOfSpecialChar(key) > -1 ? `${path}?.['${key}']` : `${path}?.${key}`
    if (Array.isArray(obj)) {
      content = `${path}?.[${key}]`
    }
    // const content = `${path}.${key}`
    if (Array.isArray(value) && value != null) {
      for (let i = 0; i < value?.length; i++) {
        const newpath = `${content}?.[${i}]`
        result.push(...groupChildKeys(value[i], newpath))
      }
      result.push({
        display: makeDislay(content),
        id: content,
        value
      })
    } else if (typeof value === 'object' && value !== null) {
      result.push({
        display: makeDislay(content),
        id: content,
        value
      })
      result.push(...groupChildKeys(value, content))
    } else {
      result.push({
        display: makeDislay(content),
        id: content,
        value
      })
    }
  })
  return result
}
function setAllValuesToUndefined(obj) {
  Object.keys(obj || {}).forEach((key) => {
    if (typeof obj[key] === 'string') {
      obj[key] = 'undefined'
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      setAllValuesToUndefined(obj[key])
    }
  })
  return obj
}

export const makeVariables = (value) => {
  const variables = {
    context: {
      req: {
        query: {},
        body: {},
        headers: {}
      },
      res: {},
      vals: {},
      authData: {}
    }
  }

  if (value !== undefined) {
    const requestData = value?.requestSnapshot
    const responseData = value?.responseSnapshot
    const valsData = value?.vals
    variables.context.req = requestData || variables?.context?.req
    variables.context.res = responseData || {}
    variables.context.vals = valsData || {}
    variables.context.authData = value?.authData
    variables.context.inputData = value?.inputData
    variables.context.inputData = value?.inputData
    if (value?.interfaces) {
      variables.context.interfaces = value?.interfaces
    }
    value?.stepOrderJsonData?.forEach((el) => {
      if (el.type === 'ifBlock') delete variables?.context?.res?.[el?.id]
    })
  }

  return variables
}
function getUsedVariables(payloadData) {
  return Object.keys(payloadData || {}).length >= 1 ? cloneDeep(payloadData) : makeVariables()
}
export const updateGroupedSnippet = (data, usedVariables) => {
  const invocationSelectorOutput = cloneDeep(data)
  delete invocationSelectorOutput?.requestSnapshotJsonData?.ACTUAL_BODY
  delete invocationSelectorOutput?.requestSnapshot?.ACTUAL_BODY
  let { context } = makeVariables(invocationSelectorOutput)
  // merge use vals and logs vals  --start

  const usedValsWithUndefinedValue = setAllValuesToUndefined(getUsedVariables(usedVariables?.payloadData))
  const usedVariablesContext = {
    req: usedValsWithUndefinedValue?.context?.req,
    res: usedValsWithUndefinedValue?.context?.res,
    vals: usedValsWithUndefinedValue?.context?.vals
  }

  context = merge(usedVariablesContext, context)
  if (context?.inputData) {
    if (context && context.inputData && 'performlist' in context.inputData) {
      context.req.body = { ...context.req.body, ...context?.inputData?.performlist }
      delete context.inputData.performlist
    }
  }
  // merge use vals and logs vals  -- end
  const suggestionJson = { authData: [], inputData: [] }
  delete context.req?.['headers']
  delete context.req?.['url']
  delete context.req?.['requestType']
  delete context?.if
  const result2 = groupChildKeys(context.req, 'context.req')
  suggestionJson.webhookData = result2
  const suggestionArr = [...result2]
  const groupSuggestionWithkeys = (property, path) => {
    Object.entries(property).forEach(([key, value]) => {
      const content = `${path}.${key}`

      const result = groupChildKeys(value, content)
      if (!['string', 'number', 'boolean'].includes(typeof value))
        result.unshift({
          display: makeDislay(content),
          id: content,
          value: value
        })
      suggestionJson[key] = [...result]
      suggestionArr.push(...result)
    })
  }
  groupSuggestionWithkeys(context.res, 'context.res')
  groupSuggestionWithkeys(context.vals, 'context.vals')
  if (context?.authData) {
    groupSuggestionWithkeys({ authData: context.authData }, 'context')
  }
  if (context?.inputData) {
    groupSuggestionWithkeys({ inputData: context.inputData }, 'context')
  }
  if (context?.interfaces) {
    groupSuggestionWithkeys(context.interfaces, 'context.interfaces')
  }
  // if(isUserOnDH) suggestionArr.concat(INPUT_FIELDS_SUGGESTIONS)
  const updatedSuggestionArr = appendOtherLibrarySnippets(suggestionArr)
  updateSnippet(updatedSuggestionArr, MiscTypes.JAVASCRIPT)
  updateSnippet(suggestionArr, MiscTypes.JSON)
  // if (isUserOnDH) updateSnippet([...INPUT_FIELDS_SUGGESTIONS, {}])
  return { suggestionJson, context: { context } }
}

const appendOtherLibrarySnippets = (suggestionArr) => {
  const updateSuggestionObj = [
    {
      display: 'get',
      id: `axios.get(url).then(response => {
        // Handle the response data
        console.log(response.data);
      }).catch(error => {
        // Handle any errors
        console.error('Axios GET request error:', error);
      });`,
      value: 'axios.get'
    },
    {
      display: 'post',
      id: `axios.post(url, {name : "viasocket"}, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        // Handle the response
        console.log(response.data);
      })
      .catch(error => {
        // Handle any errors
        console.error('Axios POST request error:', error);
      });`,
      value: 'axios.post'
    },
    {
      display: 'put',
      id: `axios.put(url, dataToReplace)
      .then(response => {
        // Handle the response
        console.log(response.data);
      })
      .catch(error => {
        // Handle any errors
        console.error('Axios PUT request error:', error);
      });`,
      value: 'axios.put'
    },
    {
      display: 'delete',
      id: `axios.delete(url)
      .then(response => {
        // Handle the response
        console.log('Resource deleted successfully.');
      })
      .catch(error => {
        // Handle any errors
        console.error('Axios DELETE request error:', error);
      });`,
      value: 'axios.delete'
    },
    {
      display: 'patch',
      id: `axios.patch(url, dataToUpdate)
      .then(response => {
        // Handle the response
        console.log(response.data);
      })
      .catch(error => {
        // Handle any errors
        console.error('Axios PATCH request error:', error);
      });`,
      value: 'axios.patch'
    },
    {
      display: 'get',
      id: `fetch(url)
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
    `,
      value: 'fetch(get)'
    },
    {
      display: 'post',
      id: `fetch('Your URL', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key: 'value' }),
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
      value: 'fetch(post)'
    },
    {
      display: 'put',
      id: `fetch('Your URL', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key: 'new_value' }),
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));`,
      value: 'fetch(put)'
    },
    {
      display: 'delete',
      id: `fetch('Your URL', {
        method: 'DELETE',
      })
        .then(response => {
          if (response.status === 204) {
            console.log('Resource deleted successfully');
          } else {
            console.error('Error:', response.status);
          }
        })
        .catch(error => console.error('Error:', error));
      `,
      value: 'fetch(delete)'
    },
    {
      display: 'patch',
      id: `fetch('Your URL', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key: 'partial_update' }),
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
      value: 'fetch(patch)'
    }
  ]
  return [...suggestionArr, ...updateSuggestionObj]
}

export function validateDuplicateTemplateName(allScripts, newTitle) {
  let valid = true
  allScripts.forEach((script) => {
    if (script.title === newTitle) {
      valid = false
    }
  })
  return valid
}

export const updateSnippet = (paths, scope = 'javascript') => {
  const sectionKey = getInfoParamtersFromUrl()?.sectionKey
  const suggestionsPaths = [...paths]
  if (['action', 'trigger'].includes(sectionKey) && !suggestionsPaths.some((element) => element.display === 'dropdown')) {
    suggestionsPaths?.push(...INPUT_FIELDS_SUGGESTIONS)
  }
  const newSnippets = suggestionsPaths?.map((element) => {
    let name
    if (scope === 'json') {
      name = `${element.display}(${typeof element.value === 'string' ? truncateString(element.value, 25) : typeof element.value})`
    } else {
      name = `${element.display}(${
        typeof element.value === 'string' || typeof element.value === 'number' ? truncateString(element.value, 25) : typeof element.value
      })`
    }
    return {
      tabTrigger: element.display,
      name,
      content: element.id,
      scope
    }
  })
  const { snippetManager } = window.ace.require('ace/snippets')
  if (snippetManager.snippetMap[scope]) {
    snippetManager.unregister(snippetManager.snippetMap[scope], scope)
    snippetManager.snippetMap[scope] = [] // Reset the snippet map for the scope
  }
  snippetManager.register(newSnippets)
}

export function calculateTimeDifference(timestamp, position = 'logs') {
  const currentTime = Date.now()
  const timeDifference = currentTime - timestamp
  const seconds = Math.floor(timeDifference / 1000)
  if (seconds < 60) {
    return position === 'logs' ? `${seconds} seconds ago` : `${seconds} sec ago`
  }
  const minutes = Math.floor(timeDifference / (1000 * 60))
  if (minutes < 60) {
    return position === 'logs' ? `${minutes} minutes ago` : `${minutes} min ago`
  }
  const hours = Math.floor(timeDifference / (1000 * 60 * 60))
  if (hours < 24) {
    return position === 'logs' ? `${hours} hours ago` : `${hours} hrs ago`
  }
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  return `${days} days ago`
}
export function extractDomainWithoutWWW(url) {
  try {
    // Ensure the URL has a scheme (e.g., http:// or https://) before parsing
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `https://${url}`
    }
    const parsedURL = new URL(url)
    let domain = parsedURL.hostname
    // Remove "www." if it exists at the beginning of the domain
    if (domain.startsWith('www.')) {
      domain = domain.substring(4)
    }
    return domain
  } catch (error) {
    console.error('Invalid URL:', error)
    return ''
  }
}
// deprecated @addChildrenInJson
export function addChildrenInJson(key, inputJsonToUpdate, childrenToAppend, index = 0) {
  if (key.length === index) return
  for (let i = 0; i < inputJsonToUpdate?.length; i++) {
    if (inputJsonToUpdate[i].key === key[index]) {
      if (inputJsonToUpdate[i].type === 'input groups') {
        if (index + 1 === key.length) inputJsonToUpdate[i].children = childrenToAppend
        else addChildrenInJson(key, inputJsonToUpdate[i].children, childrenToAppend, index + 1)
      } else inputJsonToUpdate[i].children = childrenToAppend
      break
    }
  }
}

export function addDummyDraftData(dataToUpdate) {
  let dummyData = {}
  if (dataToUpdate?.type?.includes('function')) {
    dummyData = FunctionInitialInstance
  }
  if (dataToUpdate?.type === BlockTypes.API || dataToUpdate?.type === BlockTypes.DRY_RUN) {
    dummyData = ApiInitialInstance
  }
  if (dataToUpdate?.type === BlockTypes.VARIABLE) {
    dummyData = VariableInitialInstance
  }
  if (dataToUpdate?.type === BlockTypes.IFBLOCK) dummyData = IfInitialInstance
  if (dataToUpdate?.type === BlockTypes.PLUG) {
    dummyData = initialStatePlugin
  }
  return { ...dummyData, ...dataToUpdate }
}

export const getNextUntitledName = (parentObj, name = 'Untitled') => {
  // name = name.charAt(0).toUpperCase() + name.slice(1)
  const namesArray = Object.keys(parentObj)
  let newName = name
  let counter = 1

  while (namesArray.includes(newName)) {
    newName = `${name}${counter}`
    counter++
  }

  return newName
}

export const generateUniqueTitles = (arrayOfObjects, name = 'untitled') => {
  name = name.charAt(0).toUpperCase() + name.slice(1)
  const titleSet = new Set(arrayOfObjects.map((obj) => obj.title))
  let counter = 1

  while (titleSet.has(`${name} ${counter}`)) {
    counter++
  }

  return `${name} ${counter}`
}

export const removeFromOrder = (obj, stringToDelete) => {
  try {
    Object.keys(obj || {}).forEach((key) => {
      if (Array.isArray(obj[key])) {
        const index = obj[key].indexOf(stringToDelete)
        if (index > -1) {
          obj[key].splice(index, 1)
        }
      }
    })
    return obj
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const replaceFromOrder = (oldValue, newValue, order, root = 'root') => {
  for (let i = 0; i < order[root].length; i++) {
    if (order[root][i] === oldValue) {
      order[root][i] = newValue
      break
    } else if (Object.keys(order)?.includes(order[root][i])) {
      replaceFromOrder(oldValue, newValue, order, order[root][i])
    }
  }
}

export function getNextVersion(currentVersion) {
  try {
    const versionParts = currentVersion?.split('.')
    // eslint-disable-next-line radix
    const patch = parseInt(versionParts[2])
    const nextPatch = patch + 1
    const nextVersion = `${versionParts[0]}.${versionParts[1]}.${nextPatch}`

    return nextVersion
  } catch (error) {
    console.error(error)
    return '0.0.1'
  }
}

export function pickByKeys(obj, keysToPick) {
  const pickedObject = {}
  keysToPick.forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj?.hasOwnProperty(key)) {
      pickedObject[key] = obj[key]
    }
  })

  return pickedObject
}

export function compareValuesLodash(previousObj, newObj) {
  const previousValues = pickByKeys(previousObj, Object.keys(newObj))
  return isEqual(previousValues, newObj)
}

export function timeConverter(timeToUpdate) {
  const a = new Date(timeToUpdate * 1000)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  const hour = a.getHours()
  const min = a.getMinutes()
  const sec = a.getSeconds()
  const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`
  return time
}

export const Notes = {
  response: (
    <Box className='p-3 mt-3 notes-bg'>
      <Typography className='font-bold'>Avoid initializing Variables 📌</Typography>
      <Typography>Always initialize variable outside of response block to prevent unexpected errors </Typography>
      <br />
      <Typography className='font-bold'>Keep Functions Simple ➖</Typography>
      <Typography>
        When adding functions, make them short, ideally one line, to prevent mistakes and make your code easy to read.👓📝
      </Typography>
      <br />
      <Typography className='font-bold'>Return What&apos;s Needed 📉</Typography>
      <Typography>
        Only include necessary data in the response to minimize processing and transfer overhead. Leave out unnecessary information. 🚀✨
      </Typography>
    </Box>
  ),
  function: (
    <Box className='p-3 notes-bg mt-3'>
      <Typography className='font-bold'>Syntax 📝</Typography>
      <Typography>Just include the core code of the function.</Typography>
      <br />
      <Typography className='font-bold'>Supported Libraries 📚</Typography>
      <Typography>moment, axios, FormData, https, fetch and crypto are supported inside the function.</Typography>
      <Typography
        className='info-color flex-start-center gap-1'
        onClick={() => {
          window.open('https://dev-interface.viasocket.com/i/65f01b9b4bc027b8ec12a2ed')
        }}
      >
        Click for more details
        <OpenInNewIcon fontSize='small' />
      </Typography>
      <br />
      <Typography className='font-bold'>Logging Messages</Typography>
      <Typography>
        The console allows developers to output messages from their code, which can help in debugging and understanding how the code is
        running.
      </Typography>
      <br />
      <Typography className='font-bold'>Read Blog 📚🔍</Typography>
      <Typography>
        For more information, please read our{' '}
        <a rel='noreferrer' href='https://viasocket.com/faq/functions' target='_blank'>
          blog.
        </a>
      </Typography>
    </Box>
  ),
  variable: (
    <Box className='notes-bg p-3 mt-3'>
      <Typography className='font-bold'>How to Write 📝</Typography>
      <Typography>Use regular javascript syntax to assign values to variables. For text, use quotes like &quot;name&quot;.</Typography>
      <br />
      <Typography className='font-bold'>Start Simple</Typography>
      <Typography>Create variables with the necessary information, without adding extra code.</Typography>
      <br />
      <Typography className='font-bold'>Use Anywhere 🌐</Typography>
      <Typography>Once initialized, you can use these variables anywhere you need, like in functions, API calls, or conditions.</Typography>
      <br />
      <Typography className='font-bold'>Read Blog 📚🔍</Typography>
      <Typography>
        For more information, please read our{' '}
        <a rel='noreferrer' href='https://viasocket.com/faq/variable-customizations' target='_blank'>
          blog.
        </a>
      </Typography>
    </Box>
  ),
  if: (
    <Box className='p-3 mt-3 notes-bg'>
      <Typography className='font-bold'>Check a Condition First ✔️</Typography>
      <Typography>Before doing something, make sure a condition is met, like checking if something is true: value === true.</Typography>
      <br />
      <Typography className='font-bold'>Any Type of Check 🔄</Typography>
      <Typography>This check can be for anything - a number, text, or something stored in a variable you made before.</Typography>
      <br />
      <Typography className='font-bold'>Use JavaScript Rules 📜 </Typography>
      <Typography>Write these checks using regular JavaScript rules, just like you normally would.</Typography>
      <br />
      <Typography className='font-bold'>Read Blog 📚🔍</Typography>
      <Typography>
        For more information, please read our{' '}
        <a rel='noreferrer' href='https://viasocket.com/faq/conditional-logic' target='_blank'>
          blog.
        </a>
      </Typography>
    </Box>
  ),
  transferOptionNotes: (
    <Box className='p-3 mt-3 notes-bg'>
      <Typography className='font-bold'>Match Function Output to Input Format 🔄</Typography>
      <Typography>
        Function&apos;s output format will be same as the value&apos;s format passed through flow for trigger activation{' '}
      </Typography>
    </Box>
  ),
  cron: (
    <Box className='p-3 mt-3 notes-bg'>
      <Typography className='font-bold'>Be Clear 🗣</Typography>
      <Typography>Use simple and clear terms when setting up your schedule.</Typography>
      <br />
      <Typography className='font-bold'>Match Your Needs 🎯</Typography>
      <Typography> Make sure your schedule&apos;s description clearly states what you need it to do.</Typography>
      <br />
      <Typography className='font-bold'>Timing ⏱</Typography>
      <Typography>Cron jobs can only run every minute at the least, not more often.</Typography>
      <br />
      <Typography className='font-bold'>Read Blog 📚🔍</Typography>
      <Typography>
        For more information, please read our{' '}
        <a rel='noreferrer' href=' https://viasocket.com/faq/scheduled-tasks' target='_blank'>
          blog.
        </a>
      </Typography>
    </Box>
  ),
  emailToFlow: (
    <Box className='p-3 mt-3 notes-bg'>
      <Typography className='font-bold'>Automate Flow ✉️✨</Typography>
      <Typography>Forward emails to this flow you want to automate</Typography>
      <br />
      <Typography className='font-bold'>Smart Filtering for Targeted Forwarding 🎯</Typography>
      <Typography>
        Enable forwarding for specific emails effortlessly by applying filters, such as those containing resumes or originating from
        specific IDs.
      </Typography>
      <br />
      <Typography className='font-bold'>Read Blog 📚🔍</Typography>
      <Typography>
        For more information, please read our{' '}
        <a rel='noreferrer' href='https://viasocket.com/faq/email-to-flow' target='_blank'>
          blog.
        </a>
      </Typography>
    </Box>
  ),
  API: (
    <Box className='p-3 mt-3 notes-bg'>
      <Typography className='font-bold'>API Basics 🔄</Typography>
      <Typography>APIs help software talk to each other without needing to know complex code</Typography>
      <br />
      <Typography className='font-bold'>Custom API Control 🛠️</Typography>
      <Typography>
        With viaSocket&apos;s Custom API, you can easily make specific requests for tasks like getting or changing data
      </Typography>
      <br />
      <Typography className='font-bold'>Streamlined Workflows</Typography>
      <Typography>viaSocket&apos;s Custom API automates tasks, making work faster and easier.</Typography>
      <br />
      <Typography className='font-bold'>Read Blog 📚🔍</Typography>
      <Typography>
        For more information, please read our{' '}
        <a rel='noreferrer' href='https://viasocket.com/faq/custom-api-integration' target='_blank'>
          blog.
        </a>
      </Typography>
    </Box>
  )
}

export function validateOrgName(orgName, orgList, setOrgListVariable = () => {}) {
  orgName = orgName?.trim()
  const isDuplicate = orgList.find((org) => org.name === orgName)
  if (isDuplicate) {
    errorToast('Your Organisation name already exists')
    return false
  }
  if (orgName?.length === 0) {
    setOrgListVariable(orgList)
    return false
  }
  if (orgName?.length < 3) {
    errorToast('Your Organisation name too short')
    return false
  }
  if (orgName?.length > 25) {
    errorToast('Your Organisation name must be less than 25 characters')
    return false
  }
  return true
}

export function getAvailableVariables(stepOrder, currentSlugName, allSuggestion) {
  const generateContent = (arr) => arr?.map((element) => `${element?.content} = ${JSON.stringify(element?.value)}`).join('\n')

  let variablesTemporary = ''

  const webhookDataArr = allSuggestion?.webhookData
  if (webhookDataArr) {
    variablesTemporary += `${generateContent(webhookDataArr)}\n`
  }

  // if (chatbotDetails.stepType === 'dhActionJSON' || (sectionId && currentStepId)) {
  //   variablesTemporary += generateContent(allSuggestion.authData) + '\n';
  //   variablesTemporary += generateContent(allSuggestion.inputData);
  // } else
  // {
  for (let i = 0; i < stepOrder?.length; i++) {
    const stepId = stepOrder[i]
    if (stepId === currentSlugName) break
    const stepSuggestionArr = allSuggestion?.[stepId]
    variablesTemporary += `${generateContent(stepSuggestionArr)}\n`
  }
  // }

  return variablesTemporary
}

export class DeepSet extends Set {
  add(o) {
    // eslint-disable-next-line
    for (const i of this) if (isEqual(o, i)) return this
    super.add(o) // Modified to use this context correctly
    return this
  }

  has(o) {
    // eslint-disable-next-line
    for (const i of this) if (isEqual(o, i)) return true
    return false
  }
}

export function timeAgo(dateString) {
  const date = new Date(dateString)
  const now = new Date()
  const seconds = Math.round((now - date) / 1000)
  const minutes = Math.round(seconds / 60)
  const hours = Math.round(minutes / 60)
  const days = Math.round(hours / 24)

  if (seconds < 60) {
    return `${seconds} ${seconds === 1 ? 'second' : 'seconds'}  ago`
  }
  if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}  ago`
  }
  if (hours < 24) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}  ago`
  }
  return `${days} ${days === 1 ? 'day' : 'days'}  ago`
}
export function millisecondsToSeconds(milliseconds) {
  const seconds = Math.ceil(milliseconds / 1000)
  return seconds
}

export function convertToReadableFormatIST(dateTimeString) {
  const date = new Date(dateTimeString)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Kolkata',
    timeZoneName: 'short'
  }
  return date.toLocaleString('en-IN', options)
}

export function formatDateRange(days) {
  const daysAgo = days === '3days' ? 3 : 7

  const endDate = new Date()
  endDate.setDate(endDate.getDate() - 1)

  const startDate = new Date(endDate)
  startDate.setDate(endDate.getDate() - (daysAgo - 1))
  function formatDate(date) {
    const monthAbbreviations = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec'
    }
    const month = date.getMonth()
    const day = date.getDate()
    const monthName = monthAbbreviations[month]

    return `${day} ${monthName}`
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`
}
function getDomain() {
  const hostname = window.location.hostname
  const parts = hostname?.split('.')
  if (parts.length >= 2) {
    parts.shift() // Remove the subdomain part
    return `.${parts.join('.')}`
  }
  return hostname
}

export const getSubdomain = () => {
  return window.location.hostname
}

export const getCurrentEnvironment = () => process.env.REACT_APP_API_ENVIRONMENT

export const setInCookies = (key, value) => {
  const domain = getDomain()
  let expires = ''

  const date = new Date()
  date.setTime(date.getTime() + 2 * 24 * 60 * 60 * 1000)
  expires = `; expires= ${date.toUTCString()}`
  document.cookie = `${key}=${value || ''}${expires}; domain=${domain}; path=/`
}

function splitFromFirstEqual(str) {
  // Handle empty string or string without an equal sign gracefully
  if (!str || str.indexOf('=') === -1) {
    return [str, ''] // Return the original string as both parts
  }

  // Find the index of the first equal sign
  const index = str.indexOf('=')

  // Handle cases where the equal sign is at the beginning or end of the string
  if (index === 0) {
    return ['', str.slice(1)] // Empty key, value is the rest of the string
  }
  if (index === str.length - 1) {
    return [str.slice(0, -1), ''] // Key is the entire string except the last character (equal sign)
  }

  // Split the string into key and value parts
  const key = str.slice(0, index)
  const value = str.slice(index + 1)

  return [key, value]
}

export const getFromCookies = (cookieId) => {
  // Split cookies string into individual cookie pairs and trim whitespace
  const cookies = document.cookie?.split(';').map((cookie) => cookie.trim())
  // Loop through each cookie pair
  for (let i = 0; i < cookies.length; i++) {
    // const cookiePair = cookies[i]?.split('=');
    // If cookie name matches, return its value
    const [key, value] = splitFromFirstEqual(cookies[i])
    if (cookieId === key) {
      return value
    }
  }
  // If the cookie with the given name doesn't exist, return null
  return null
}

export const removeCookie = (cookieName) => {
  const domain = getDomain()
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`
}

export function generateRandomString() {
  const length = 20
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.-_'
  let randomString = ''

  if (typeof window !== 'undefined' && window.crypto && window.crypto.getRandomValues) {
    const values = new Uint32Array(length)
    window.crypto.getRandomValues(values)

    for (let i = 0; i < length; i++) {
      randomString += charset[values[i] % charset.length]
    }
  } else {
    // Fallback for environments that don't support crypto.getRandomValues
    for (let i = 0; i < length; i++) {
      randomString += charset.charAt(Math.floor(Math.random() * charset.length))
    }
  }

  return randomString
}

export const checkIfArraysEmpty = (obj) => {
  let hasNonEmptyArray = false // Flag variable to track if any array has a length greater than zero

  Object.values(obj).forEach((element) => {
    Object.values(element).forEach((val) => {
      Object.values(val).forEach((ele) => {
        if (Array.isArray(ele) && ele.length > 0) {
          hasNonEmptyArray = true
        }
      })
    })
  })

  return hasNonEmptyArray
}

export const sendDataToParentInEmbed = (action, flowId, description, title, payloadDataToSend) => {
  const webhookUrlData = {
    webhookurl: `${process.env.REACT_APP_WEBHOOK_URL}/func/${flowId}`,
    payload: payloadDataToSend,
    action,
    id: flowId,
    description,
    title
  }
  window?.parent?.postMessage(webhookUrlData, '*')
}
export function RenderIcons({ flowJsonBlocks = {}, size = '24px', alternate = '' }) {
  let icons = []
  const handleIcon = (data) => {
    if (data === 'api') return <ApiSharp sx={{ width: size, height: size }} />
    if (data === 'variable') return <CodeIcon sx={{ width: size, height: size }} />
    if (data === 'function') return <JavascriptOutlined sx={{ width: size, height: size }} />
    return <img src={data} alt='icon url' width={size} height={size} />
  }
  Object.values(flowJsonBlocks).forEach((block) => {
    if (block.type === 'plugin' && block.iconUrl) icons.push(block?.iconUrl)
    // else icons.push(block.type)
    if (block.type === 'variable') icons.push(block.type)
    if (block.type === 'api') {
      if (block.url) icons.push(block.url)
      else icons.push('api')
    }
    if (block.type === 'function') icons.push(block.type)
  })
  icons = [...new Set(icons)]
  return icons.length > 0 ? (
    <Box className='flex-start-center   flex-wrap gap-1'>
      {icons.slice(0, 8).map((url) => handleIcon(url))}
      {icons.length > 8 && '...'}
    </Box>
  ) : (
    <Typography sx={{ fontSize: size }}>{alternate}</Typography>
  )
}

/* eslint-disable */
export function handleDictionaryForExecutionScript(inputObject) {
  if (typeof inputObject === 'string' || typeof inputObject === 'number' || typeof inputObject === 'boolean') return
  if (inputObject === undefined || inputObject === null) return
  for (const key in inputObject) {
    if (inputObject[key] === 'dictionary') {
      const dictionaryKiJey = key?.replace('-Type', '')?.substring(1)
      if (dictionaryKiJey && inputObject[dictionaryKiJey]) {
        const dictionaryKiJson = inputObject[dictionaryKiJey]
        const newObjectToAppend = {}
        // filters on UUID to create json
        for (const dictionaryUuids in dictionaryKiJson) {
          if (dictionaryKiJson[dictionaryUuids].key) {
            newObjectToAppend[dictionaryKiJson[dictionaryUuids].key] = dictionaryKiJson?.[dictionaryUuids]?.value || ''
            newObjectToAppend[`_${dictionaryKiJson[dictionaryUuids].key}`] = dictionaryKiJson?.[dictionaryUuids]?._value
            newObjectToAppend[`_${dictionaryKiJson[dictionaryUuids].key}-Type`] = dictionaryKiJson?.[dictionaryUuids]?.['_value-Type']
          }
        }
        inputObject[dictionaryKiJey] = newObjectToAppend
      }
    }
    handleDictionaryForExecutionScript(inputObject[key])
  }
}
export function removereplaceKeyWithUnderscoreForPlugin(inputObject) {
  // If the input is a string, return it as is
  if (typeof inputObject === 'string' || typeof inputObject === 'number' || typeof inputObject === 'boolean') return inputObject
  if (Array.isArray(inputObject)) return inputObject

  // Initialize an empty object to store filtered keys and values
  const filteredObject = {}

  // Iterate through the keys of the input object
  for (const key in inputObject) {
    // Check if the key is a direct property of the inputObject
    if (inputObject.hasOwnProperty(key)) {
      // Check if the key starts with '_' and the value is 'number' or 'boolean'
      if (key.startsWith('_') && (inputObject[key] === 'number' || inputObject[key] === 'boolean')) {
        // Remove the underscore prefix and split the key by '-'
        const keyWithout_ = key.substring(1).split('-')

        // Check if the modified key is longer than 0 and ends with 'Type'
        if (keyWithout_.length > 0 && keyWithout_[keyWithout_.length - 1] === 'Type') {
          // Check if the first part of the modified key exists as a key in the inputObject
          if (keyWithout_[0] in inputObject && inputObject[keyWithout_[0]]?.toString()?.trim() != '') {
            // Create a new key without '_' and add the modified value to the filteredObject
            filteredObject[keyWithout_[0]] = `~${inputObject[keyWithout_[0]]}~`
          } else if (keyWithout_[0] in inputObject && inputObject[keyWithout_[0]].trim() === '') {
            delete inputObject[keyWithout_[0]]
          }
        }
      }
      // Check if the key is not already added to the filteredObject
      if (!filteredObject.hasOwnProperty(key)) {
        // Recursively call the function on the nested object and assign the result to the filteredObject
        filteredObject[key] = removereplaceKeyWithUnderscoreForPlugin(inputObject[key])
      }
    }
  }
  // Return the filtered object with modified keys
  return filteredObject
}

export function removeKeyWithUnderscore(inputObject) {
  const filteredObject = {}

  for (const key in inputObject) {
    if (!key.startsWith('_')) {
      if (Array.isArray(inputObject[key])) {
        filteredObject[key] = inputObject[key]
      } else if (typeof inputObject[key] === 'object') {
        filteredObject[key] = removeKeyWithUnderscore(inputObject[key])
      } else {
        filteredObject[key] = inputObject[key]
      }
    }
  }
  return filteredObject
}

export function replaceQuotesWithBackticks(obj) {
  if (typeof obj === 'string') {
    return `\`${obj}\``
  }

  if (Array.isArray(obj)) {
    // Iterate over the array and replace all double quotes in each element.
    return obj.map(replaceQuotesWithBackticks)
  }

  if (typeof obj === 'object' && obj != null) {
    // Iterate over the dictionary and replace all double quotes in each value.
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = replaceQuotesWithBackticks(obj[key])
      return acc
    }, {})
  }
  return obj
}

export function replaceQuotes(input) {
  // Replace all double quotes and backticks with single backticks.
  return input.replace(/"(`.*?`)"(?!:)/g, '$1')
}

export function replaceQuotesForPlugin(input) {
  // Replace all double quotes and backticks with single backticks.
  return input.replace(/`~(.*?)~`(?!:)/g, '$1')
}
export function getStringFromUsedVariable(inputObjectValue) {
  let filteredUsedVariables = cloneDeep(inputObjectValue)
  filteredUsedVariables = flattenToJSON(filteredUsedVariables)
  handleDictionaryForExecutionScript(filteredUsedVariables)
  filteredUsedVariables = removereplaceKeyWithUnderscoreForPlugin(filteredUsedVariables)
  filteredUsedVariables = removeKeyWithUnderscore(filteredUsedVariables)
  filteredUsedVariables = replaceQuotesWithBackticks(filteredUsedVariables)
  filteredUsedVariables = JSON.stringify(filteredUsedVariables)
  filteredUsedVariables = replaceQuotes(filteredUsedVariables)
  filteredUsedVariables = replaceQuotesForPlugin(filteredUsedVariables)
  return filteredUsedVariables
}

export const createObjectOfUsedVariablesPluginString = (pluginUsedVariables, context) => {
  if (pluginUsedVariables === null || pluginUsedVariables === undefined || Object.keys(pluginUsedVariables).length === 0) return {}
  return evalVariableAndCodeFromContext(`return ${getStringFromUsedVariable(pluginUsedVariables)}`, context)
}

function createCurlCommand(usedVariables, URL) {
  const hasBody = usedVariables.body && Object.keys(usedVariables.body).length > 0
  const hasQuery = usedVariables.query && Object.keys(usedVariables.query).length > 0

  // Construct query parameters string
  const queryParams = hasQuery
    ? '?' +
      Object.entries(usedVariables.query)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')
    : ''

  // Initialize an array to hold cURL command parts
  const curlParts = [`curl -X ${hasBody ? 'POST' : 'GET'} "${URL}${queryParams}"`]

  if (hasBody) {
    const body = JSON.stringify(usedVariables.body, null, 2) // Pretty-print JSON
    curlParts.push(`  -H "Content-Type: application/json"`)
    // Escape single quotes in the JSON body
    const escapedBody = body.replace(/'/g, `'\\''`)
    curlParts.push(`  -d '${escapedBody}'`)
  }

  // Join the parts with backslashes and line breaks for formatting
  const formattedCurl = curlParts.join(' \\\n')
  return formattedCurl
}

export const webhookSupportedLanguages = (method, url, scriptId) => {
  const urlString = `'${url}'`
  const usedVariables = store.getState()?.scripts?.scripts?.[scriptId]?.used_variables?.payloadData?.context?.req || {}
  return {
    curl: {
      name: 'cURL',
      mode: 'javascript',
      code: createCurlCommand(usedVariables, url)
    },
    node: {
      name: 'Node',
      mode: 'javascript',
      code: `const https = require('https');
      const url = ${urlString};
      https.${method}(url, (response) => {
        let data = '';
        // A chunk of data has been received.
        response.on('data', (chunk) => {
          data += chunk;
        });
      
        // The whole response has been received.
        response.on('end', () => {
          console.log(data);
        });
      }).on('error', (error) => {
        console.error(\`Error making GET request: \${error.message}\`);
      });
    `
    },
    python: {
      name: 'Python',
      mode: 'python',
      code: `import requests
  
url = "${url}"
  
try:
    response = requests.get(url)
    response.raise_for_status()  # Raise an exception for HTTP errors
    print(response.text)
except requests.exceptions.RequestException as e:
    print(f'Error making GET request: {e}')
  `
    },
    javascript: {
      name: 'Javascript',
      mode: 'javascript',
      code: `const url = "${url}";
  
     fetch(url)
       .then(response => {
         if (!response.ok) {
           throw new Error(\`HTTP error! Status: \${response.status}\`);
         }
         return response.text();
       })
       .then(data => {
         console.log(data);
       })
       .catch(error => {
         console.error('Fetch error:', error);
       });`
    },
    php: {
      name: 'PHP',
      mode: 'php',
      code: `<?php
      $url = '${url}';
      
      $ch = curl_init($url);
      curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
      $response = curl_exec($ch);
      
      if ($response === false) {
          echo "Failed to fetch data from $url: " . curl_error($ch);
      } else {
          echo $response;
      }
      
      curl_close($ch);
      ?>`
    },
    java: {
      name: 'JAVA',
      mode: 'java',
      code: `import java.io.BufferedReader;
      import java.io.InputStreamReader;
      import java.net.HttpURLConnection;
      import java.net.URL;
      
      public class Main {
          public static void main(String[] args) {
              String url = "${url}";
      
              try {
                  URL obj = new URL(url);
                  HttpURLConnection con = (HttpURLConnection) obj.openConnection();
      
                  // Set the request method to GET
                  con.setRequestMethod("GET");
      
                  // Get the response code
                  int responseCode = con.getResponseCode();
      
                  if (responseCode == HttpURLConnection.HTTP_OK) {
                      BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));
                      String inputLine;
                      StringBuilder response = new StringBuilder();
      
                      while ((inputLine = in.readLine()) != null) {
                          response.append(inputLine);
                      }
                      in.close();
      
                      // Print the response body
                      System.out.println(response.toString());
                  } else {
                      System.out.println("HTTP Error: " + responseCode);
                  }
              } catch (Exception e) {
                  System.out.println("Error making GET request: " + e.getMessage());
              }
          }
      }
      `
    },
    ruby: {
      name: 'Ruby',
      mode: 'ruby',
      code: `require 'net/http'
  
      url = URI.parse('${url}')
      
      begin
        response = Net::HTTP.get_response(url)
      
        if response.is_a?(Net::HTTPSuccess)
          puts response.body
        else
          puts "HTTP Error: #{response.code} #{response.message}"
        end
      rescue StandardError => e
        puts "Error making GET request: #{e.message}"
      end
      `
    },
    clojure: {
      name: 'Clojure',
      mode: 'clojure',
      code: `(ns your-namespace
        (:require [clojure.java.io :as io]))
      
      (defn make-get-request [url]
        (try
          (with-open [reader (io/reader (java.net.URL. url))]
            (let [response (slurp reader)]
              (println response)))
          (catch Exception e
            (println (str "Error making GET request: " (.getMessage e))))))
      
      (def url "${url}")
      
      (make-get-request url)
      `
    },
    go: {
      name: 'go',
      mode: 'golang',
      code: `package main
  
      import (
        "fmt"
        "io/ioutil"
        "net/http"
      )
      
      func main() {
        url := "${url}"
      
        response, err := http.Get(url)
        if err != nil {
          fmt.Printf("Error making GET request: %s", err)
          return
        }
        defer response.Body.Close()
      
        if response.StatusCode != http.StatusOK {
          fmt.Printf("HTTP Error: %s", response.Status)
          return
        }
      
        body, err := ioutil.ReadAll(response.Body)
        if err != nil {
          fmt.Printf("Error reading response body: %s", err)
          return
        }
      
        fmt.Println(string(body))
      }
      `
    },
    kotlin: {
      name: 'Kotlin',
      mode: 'kotlin',
      code: `import java.net.HttpURLConnection
      import java.net.URL
      import java.io.BufferedReader
      import java.io.InputStreamReader
      
      fun main() {
          val url = "${url}"
      
          try {
              val connection = URL(url).openConnection() as HttpURLConnection
              connection.requestMethod = "GET"
      
              val responseCode = connection.responseCode
      
              if (responseCode == HttpURLConnection.HTTP_OK) {
                  val response = connection.inputStream.bufferedReader().use(BufferedReader::readText)
                  println(response)
              } else {
                  println("HTTP Error: $responseCode - \${connection.responseMessage}")
              }
          } catch (e: Exception) {
              println("Error: \${e.message}")
          }
      }
      `
    }
  }
}

export function validateCompanyBilling(obj) {
  const invalidFields = []

  // Helper function to validate fields
  function validateField(fieldName, fieldCondition, parentObj = obj) {
    if (!fieldCondition) {
      invalidFields.push(fieldName)
    }
  }

  // Validate individual fields
  validateField('firstName', obj.firstName && typeof obj.firstName === 'string')
  validateField('lastName', obj.lastName && typeof obj.lastName === 'string')
  validateField('companyName', obj.companyName && typeof obj.companyName === 'string')
  validateField('billingEmail', obj.biller_email && typeof obj.biller_email === 'string' && validateEmail(obj.biller_email))
  validateField('AddressLine1', obj.address && obj.address.AddressLine1 && typeof obj.address.AddressLine1 === 'string', obj.address)
  validateField('city', obj.city && typeof obj.city === 'string')
  validateField('state', obj.state && typeof obj.state === 'string')
  validateField('postalCode', obj.postal_code && typeof obj.postal_code === 'string')
  validateField('phone', obj.contact_number && typeof obj.contact_number === 'string')

  // Construct a string of invalid fields
  const invalidFieldsString = invalidFields.join(', ')

  return {
    isValid: invalidFields.length === 0,
    invalidFields: invalidFieldsString
  }
}

// Assumes validateEmail is a function defined elsewhere that checks if the email is valid

export function getRemainingTime(expiryDate) {
  const currentDate = new Date()
  const expiry = new Date(expiryDate)
  const timeDiff = expiry - currentDate

  if (timeDiff < 0) {
    return 'expired'
  }
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
  if (daysDiff > 30) {
    const yearsDiff = expiry.getFullYear() - currentDate.getFullYear()
    const monthsDiff = expiry.getMonth() - currentDate.getMonth()
    const totalMonthsDiff = yearsDiff * 12 + monthsDiff
    return `${totalMonthsDiff} months`
  }
  return `${daysDiff} days`
}

export function getRemainingDays(expiryDate) {
  const currentDate = new Date()
  const expiry = new Date(expiryDate)
  const timeDiff = expiry - currentDate
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
  return daysDiff
}
export function extractDomainFromString(str) {
  const domainRegex = /(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,})+)/
  const match = str?.match(domainRegex)
  return match ? match[1] : null
}

/*
utility funciton to check if the user is created today or not  
*/
export function isUserCreatedToday(createdTimeStr) {
  // Parse the created time string
  const createdTime = new Date(createdTimeStr)

  // Get the current time
  const currentTime = new Date()

  // Extract year, month, and date for both created time and current time
  const createdDate = createdTime.toISOString().split('T')[0]
  const currentDate = currentTime.toISOString().split('T')[0]

  // Compare the dates
  return createdDate === currentDate
}
/*
utility funciton to get and remove the utm source form the previous url 
*/
export function removeUTMSource(url) {
  if (!url) return { utmSource: null, url: null }
  let utmSource = null
  const baseUrl = process.env.REACT_APP_FRONTEND_URL

  // Prepend the base URL to the relative URL
  let fullUrl = baseUrl + url

  // Create a URL object
  let urlObj = new URL(fullUrl)
  let params = new URLSearchParams(urlObj.search)

  // Remove the 'utm_source' parameter if it exists
  if (params.has('utm_source')) {
    utmSource = params.get('utm_source')
    params.delete('utm_source')
  }

  // Reconstruct the URL without the 'utm_source' parameter
  urlObj.search = params.toString()

  // Remove the base URL from the final URL
  let finalUrl = urlObj.pathname + urlObj.search + urlObj.hash

  return { utmSource, url: finalUrl }
}
/*
utility funciton to send utm source of user on flow
*/
export async function sendUserUtmSource(utm_source, user_id) {
  try {
    await axios.post('https://flow.sokt.io/func/scriItCGd1y3', {
      utm_source: utm_source,
      id: user_id,
      enviroment: process.env.REACT_APP_API_ENVIRONMENT
    })
  } catch (error) {
    sendAlert({ message: error.message, type: ALERTTYPES.FRONTEND, description: 'unable to send utm source' })
  }
}

export function doesValueExists(value) {
  return (
    typeof value === 'boolean' ||
    typeof value === 'number' ||
    (value &&
      ((typeof value === 'string' && value?.trim()?.length) ||
        (Array.isArray(value) && value?.length) ||
        (typeof value === 'object' && Object.keys(value)?.length)))
  )
}

/**
 * Prettify JSON data by parsing and formatting it with proper indentation.
 * @param {string} json - The JSON data to be prettified.
 * @returns {string} The prettified JSON data.
 */
export const prettifyJson = (json) => {
  if (!json?.trim()) return json.trim()
  try {
    const jsonObject = JSON.parse(json)
    const prettyJson = JSON.stringify(jsonObject, null, 2)
    return prettyJson
  } catch (error) {
    return json
  }
}

export const getProxyAuthToken = () => {
  const mode = store.getState().appInfo.mode
  let proxyauthtoken = ''
  const params = new URLSearchParams(window.location.search)
  if (getCurrentEnvironment() === 'local') {
    proxyauthtoken = localStorage.getItem('proxy_auth_token')
  } else if (params.get('mode') === 'embed' || ['embed', 'sso'].includes(mode)) {
    proxyauthtoken = sessionStorage.getItem('ssoEmbedToken')
  } else {
    proxyauthtoken = getFromCookies(getCurrentEnvironment())
  }
  return proxyauthtoken
}

export function fetchUsedVariables(codeString = '', inDH = false) {
  function replaceOptionalChaining() {
    // Replace optional chaining followed by bracket notation with direct bracket notation
    codeString = codeString?.replaceAll('?.[', '[')?.replaceAll('?.', '.')
    const regex = /(\?\.\[['"]([^'"]+)['"]\])/g
    return codeString.replace(regex, (match, p1) => p1.replace('?.', ''))
  }

  function normalizeOptionalChaining() {
    // Normalize optional chaining to dot notation
    return codeString.replace(/\?\./g, '.')
  }

  function findContextPaths() {
    // Match and return unique sets of context paths
    const regex = /context(\?\.\w+|\.\w+|\[\d+\]|(\?\.)?\[['"]([^'"]+)['"]\])+/g
    const matches = codeString.match(regex)
    return new Set(matches)
  }

  function removeMethodCalls() {
    // Define the regex pattern to match method calls and any subsequent property accesses or method calls
    const methodCallRegex = /\.(\w+)\(\)(\.\w+|\(\))*\.?/g

    // Replace all method calls and their chaining with an empty string
    return codeString.replace(methodCallRegex, '').trim()
  }

  // Process the codeString
  codeString = replaceOptionalChaining()
  codeString = normalizeOptionalChaining()
  codeString = removeMethodCalls()

  // Extract unique context paths
  const uniqueMatchesSet = findContextPaths()
  // Convert the set to an array and return it
  return Array.from(uniqueMatchesSet).filter((variable) => inDH || (!variable.includes('inputData') && !variable.includes('authData')))
}
export function parseString(input) {
  try {
    // Check for null or undefined
    if (input === 'null') return null
    if (input === 'undefined') return undefined
    // Check for boolean
    if (input === 'true') return true
    if (input === 'false') return false
    // Check for number
    if (!isNaN(input) && !isNaN(parseFloat(input))) return parseFloat(input)
    // Check for JSON object or array

    let parsed = JSON.parse(input)
    if (typeof parsed === 'object' || Array.isArray(parsed)) return parsed
  } catch (e) {
    // Not JSON
  }

  // Return the original string if no match
  return input
}

export function isColorVisible(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '')

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  // Calculate the brightness (perceived luminance)
  let brightness = (r * 299 + g * 587 + b * 114) / 1000

  // If brightness is above a certain threshold, consider it whitish
  return brightness <= 200
} // Adjust the threshold as needed
/**
 * This function takes a string input and attempts to parse it into its appropriate data type.
 * It handles null, undefined, boolean, number, and JSON object/array types.
 * If the input does not match any of these types, it returns the original string.
 *
 * @param {string} input - The string to be parsed.
 * @returns {any} - The parsed value, which can be of type null, undefined, boolean, number, object, array, or string.
 */
export function trimObjectStrings(obj) {
  if (typeof obj === 'string') {
    return obj.trim()
  } else if (Array.isArray(obj)) {
    return obj.map(trimObjectStrings)
  } else if (typeof obj === 'object' && obj !== null) {
    const trimmedObj = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        trimmedObj[key] = trimObjectStrings(obj[key])
      }
    }
    return trimmedObj
  }
  return obj
}

export function filterArrayByText(array, searchText) {
  if (!searchText) return array

  const lowerSearchText = searchText.toLowerCase()

  // Helper function to recursively search through the object
  function searchInObject(obj, searchText) {
    if (obj == null) return false

    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      return obj.toString().toLowerCase().includes(searchText)
    }
    return false
  }

  return array.filter((item) => {
    return (item.display && item.display.toLowerCase().includes(lowerSearchText)) || searchInObject(item.value, lowerSearchText)
  })
}

/**
 * Converts a JSON object to its type representation if it exceeds a specified token limit.
 * If the JSON object is within the token limit, it returns the original JSON.
 *
 * @param {Object} json - The JSON object to be converted.
 * @param {number} [maxTokens=2048] - The maximum number of tokens allowed for the JSON object. Defaults to 2048.
 * @returns {Object|string|number|boolean|Array} - The original JSON object if within the token limit, otherwise a type representation of the JSON object.
 */

export function jsonToTypeOrRaw(json, maxTokens = 2048) {
  const jsonString = JSON.stringify(json)
  const tokenCount = Math.ceil(jsonString.length / 4) // Estimate token count (approx. 4 chars per token)

  if (tokenCount <= maxTokens) {
    return json // Return the original JSON if it's within the token limit
  } else {
    return convertToType(json)
  }
}

function convertToType(obj) {
  if (Array.isArray(obj)) {
    return obj.length > 0 ? [convertToType(obj[0])] : [] // Assume homogenous arrays
  } else if (obj !== null && typeof obj === 'object') {
    const result = {}
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = convertToType(obj[key])
      }
    }
    return result
  } else {
    return typeof obj
  }
}

/** Holds Talk To Support Dialog Card Data */
export const cardData = [
  {
    url: 'https://viasocket.com/community/',
    // bgColor: '#FFF5E2',
    imgAlt: 'community logo',
    imgSrc: community,
    title: 'Community',
    description: 'Connect with others like you to ask questions and share solutions.',
    // height: '300px',
    // cssGridRowValue: 'span 2',
    textSize: '18px'
  },
  // {
  //   url: 'https://viasocket.com/experts',
  //   bgColor: '#FFEBE9',
  //   imgAlt: 'expert logo',
  //   imgSrc: expert,
  //   title: 'Hire an expert',
  //   description: 'Get professional help to maximize your integration experience.',
  //   height: '300px',
  //   cssGridRowValue: 'span 2',
  //   textSize: '18px'
  // },
  {
    url: 'mailto: support@viasocket.com',
    // bgColor: '#E0F6FF',
    imgAlt: 'email logo',
    imgSrc: email,
    title: 'Email',
    description: 'Reach out directly were always here to assist you.',
    // height: '200px',
    // cssGridRowValue: 'auto',
    textSize: '18px'
  },
  {
    url: 'https://viasocket.com/faq/',
    // bgColor: '#FFF5E2',
    imgAlt: 'knowledge base logo',
    imgSrc: knowledge,
    title: 'Knowledge Base',
    description: 'Learn how to get the most out of viaSocket with guides and tutorials.',
    // height: '300px',
    // cssGridRowValue: 'span 2',
    textSize: '18px'
  },
  {
    url: 'https://viasocket.com/blog',
    // bgColor: '#E0F6FF',
    imgAlt: 'blog logo',
    imgSrc: blogging,
    title: 'Blogs',
    description: 'Stay updated with the latest news tips and insights.',
    // height: '200px',
    // cssGridRowValue: 'auto',
    textSize: '18px'
  },
  {
    url: 'https://roadmap.viasocket.com/b/n0elp3vg/feature-ideas',
    // bgColor: '#FFEBE9',
    imgAlt: 'feature request logo',
    imgSrc: request,
    title: 'Submit a feature request',
    description: 'Share your ideas to help improve viaSocket.',
    // height: '200px',
    // cssGridRowValue: 'auto',
    textSize: '16px'
  }
]

export function getBinaryFileFromFile(params) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(params)
  })
}

export const generateNewIdForInputField = (length = 8) => {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', length)
  return nanoid()
}

export function giveSlugNameFromChip(chipString) {
  chipString = chipString
    ?.replaceAll('?.[', '[')
    ?.replaceAll('?.', '.')
    ?.replaceAll("'", '')
    ?.replace(/\[(.*?)]/g, '.$1')
    ?.split('.')
  if (chipString[1] === 'res' || chipString[1] === 'vals') return chipString[2]
  return null
}

export function getLevelsOfNestingObject(params) {
  if (typeof params !== 'object' || params === null) {
    return 1
  }

  let maxDepth = 1
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      maxDepth += getLevelsOfNestingObject(params[key])
    }
  }

  return maxDepth + 1
}

export const getDelayTimeFromMinutesExpression = (value) => {
  const expression = value?.replace('return ', '')

  let resultInMinutes = 0
  try {
    // eslint-disable-next-line
    resultInMinutes = eval(expression)
  } catch (error) {
    console.error('Error evaluating the expression:', error)
    return null
  }

  const days = Math.floor(resultInMinutes / (24 * 60)) // Convert to days
  const hours = Math.floor((resultInMinutes % (24 * 60)) / 60) // Convert to hours
  const minutes = Math.floor(resultInMinutes % 60) // Remaining minutes
  const seconds = Math.round((resultInMinutes % 1) * 60) // Remaining seconds

  let formattedResult = ''
  if (days > 0) formattedResult += `${days} day${days > 1 ? 's' : ''} `
  if (hours > 0) formattedResult += `${hours} hour${hours > 1 ? 's' : ''} `
  if (minutes > 0) formattedResult += `${minutes} minute${minutes > 1 ? 's' : ''} `
  if (seconds > 0) formattedResult += `${seconds} second${seconds > 1 ? 's' : ''}`

  return formattedResult.trim()
}

export function lastIndexOfSpecialChar(str) {
  // Regex to match any special character or whitespace
  const specialChars = /[\s\W]/

  // Iterate backward through the string to find the last match
  for (let i = str.length - 1; i >= 0; i--) {
    if (specialChars.test(str[i])) {
      return i // Return the index of the last special character
    }
  }
  return -1 // Return -1 if no special character or space is found
}

export function makeDislay(id) {
  return `${id
    ?.replaceAll('?.[', '[')
    ?.replaceAll('?.', '.')
    ?.replaceAll("'", '')
    ?.replace(/\[(.*?)]/g, '.$1')
    .replace(/^context\.res\.|context\.req\.|context\.vals\.|context\./, '')}`
}

export function getUniqueServicesFromAuths(data = []) {
  const uniqueServices = new Map()

  data?.forEach((item) => {
    const existingService = uniqueServices.get(item.service_id)
    // Check if the service_id already exists in the map and if any auth is expired
    if (existingService) {
      if (item.isExpired) {
        existingService.haveExpiredAuth = true
      }
    } else {
      // Add unique service details to the map
      uniqueServices.set(item.service_id, {
        rowid: item.service_id,
        name: item.service_name,
        iconurl: item.iconUrl,
        haveExpiredAuth: item.isExpired // Add haveExpiredAuth key
      })
    }
  })

  // Convert the map values to an array and return it
  return uniqueServices
}

export function collapseFlowPageAccordion(toExpand = false) {
  const accordion = document.getElementById('flow-page-accordion')
  if (accordion) {
    const accordionSummary = accordion.querySelector('.MuiAccordionSummary-root')
    const isExpanded = accordion.querySelector('.Mui-expanded')
    if (accordionSummary && Boolean(isExpanded) !== toExpand) {
      accordionSummary.click()
    }
  }
}
